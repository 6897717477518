import React from 'react'
import { CheckIcon } from '@heroicons/react/solid'

import { Link } from 'gatsby'

import './index.styles.scss'

const ResaPage = () => {
    return (
        <div>
            <section className='w-full h-screen footer-bg'>
                <div className="flex justify-center">
                    <div className='w-80 justify-items-center mt-20'>
                        <div className='w-full min-h-full rounded overflow-hidden footer-card-bg bg-white bg-no-repeat bg-top bg-contain p-4'>
                            <div className='h-64'>                            
                            </div>
                            <CheckIcon className='w-40 h-40 mx-auto text-emerald-400' />
                            <div className="px-4 pt-10 pb-4 text-center ">
                            <p>Votre demande a bien été transmise. <br />
                            Nous vous recontacterons d'ici peu.  
                            </p>  
                            <p className='py-4 font-bold'>Veillez à bien vérifier votre boite spam.</p> 
                            </div>
                            <div>
                                <Link to='/'>
                                <button className='rounded w-full h-10 
                                hover:bg-cyan-500 bg-teal-300 text-teal-800'>Retour à l'Accueil</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>                
            </section>
        </div>
    )
}

export default ResaPage